import { useEffect, useRef } from "react";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

const flashQueryConsumers = new Array<(query: URLSearchParams) => boolean>();

const flashQuery = _.debounce((location: ReturnType<typeof useLocation>, navigate: ReturnType<typeof useNavigate>) => {
    const query = new URLSearchParams(location.search);
    const isConsumed = flashQueryConsumers.map((consumer) => consumer(query))
        .some((result) => result);
    if (isConsumed) {
        navigate(window.location.pathname, { replace: true });
    }
}, 50);

function useFlashQuery(consumer: (query: URLSearchParams) => boolean): void {
    const ref = useRef(consumer);
    ref.current = consumer;
    const navigate = useNavigate();
    const location = useLocation();
    
    useEffect(() => {
        const index = flashQueryConsumers.length;
        flashQueryConsumers.push((query) => {
            return ref.current(query);
        });

        return () => {
            flashQueryConsumers.splice(index, 1);
        }
    }, []);

    useEffect(() => {
        if (!navigate || !location) {
            return;
        }
        flashQuery(location, navigate);
    }, [navigate, location]);
}

export const historyUtils = {
    useFlashQuery,
}
