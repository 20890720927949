import Card, { CardProps } from "antd/lib/card";
import Tabs from "antd/lib/tabs";
import ForgotPasswordForm from "components/Forgot/ForgotPasswordForm";
import ForgotUsernameForm from "components/Forgot/ForgotUsernameForm";
import ContentPageLayout from "components/Layouts/ContentPageLayout";

function Forgot() {
    return (
        <ContentPageLayout
            pageHeader="Forgot Credentials"
        >
            <Tabs
                type="card"
                tabBarStyle={{ marginBottom: 0 }}
                items={[
                    {
                        key: "forgot-username",
                        label: "Forgot Username",
                        children: (
                            <Layout title="Recover your usernames">
                                <ForgotUsernameForm />
                            </Layout>
                        )

                    },
                    {
                        key: "forgot-password",
                        label: "Forgot Password",
                        children: (
                            <Layout title="Reset your password">
                                <ForgotPasswordForm />
                            </Layout>
                        )
                    }
                ]}
            />
        </ContentPageLayout>
    );
}

function Layout({ children, ...props }: CardProps) {
    return (
        <Card {...props}>
            <div style={{ maxWidth: 600 }}>
                {children}
            </div>
        </Card>
    )
}

export default Forgot;
