import { makeObservable, observable, reaction, runInAction } from "mobx";
import Analytics from "services/analytics/Analytics";
import GraphQL from "../api/GraphQL";
import { LoginResponse, User, UserDocument, UserQuery } from "../api/GraphqlTypes";

export default class UserStore  {
    public user: User | undefined;
    public loading: boolean = true;

    public get token(): string {
        return localStorage.getItem("token") || "";
    }

    constructor() {
        makeObservable(this, {
            user: observable,
            loading: observable
        });
        this.fetch();

        reaction(() => this.user, () => {
            GraphQL.client.stop();
            GraphQL.client.resetStore();
        });
    }

    public async fetch(): Promise<void> {
        !this.loading && runInAction(() => this.loading = true);
        const result = await GraphQL.query<UserQuery>(UserDocument, {});
        runInAction(() => {
            this.loading = false;
            if (result.data.me) {
                this.user = result.data.me as User;
            }
        });
    }

    public onLogin = (r: LoginResponse): void => {
        localStorage.setItem('token', r.token);
        runInAction(() => this.user = r.user);
        GraphQL.client.resetStore();
        Analytics.setUser(this.user?.id.toString());
    }

    public onLogout = (): void => {
        localStorage.removeItem('token');
        runInAction(() => this.user = undefined);
        GraphQL.client.resetStore();
        Analytics.setUser();
    }

    public updateUser = (user: User): void => {
        runInAction(() => this.user = { ...this.user, ...user });
    }
}
