import { FetchResult, Observable } from "@apollo/client";
import GraphQL from "services/api/GraphQL";
import { StoreItemStockUpdate, StoreItemStockUpdateDocument, StoreItemStockUpdateSubscription } from "services/api/GraphqlTypes";

type StoreItemStockConsumer = (data: StoreItemStockUpdate) => void;
type Subscription = ReturnType<ReturnType<typeof GraphQL.subscribe>["subscribe"]>;

class StoreItemsSubscriptionStore {
    private readonly consumers: StoreItemStockConsumer[] = [];
    
    private subscription: Subscription | undefined;
    private _stream: Observable<FetchResult<StoreItemStockUpdateSubscription>> | undefined;

    public get stream(): Observable<FetchResult<StoreItemStockUpdateSubscription>> {
        if (!this._stream) {
            this._stream = GraphQL.subscribe<StoreItemStockUpdateSubscription>(StoreItemStockUpdateDocument);
        }
        return this._stream;
    }

    public subscribe(consumer: StoreItemStockConsumer): Subscription {
        if (!this.subscription || this.subscription.closed) {
            this.subscription = this.stream.subscribe(this.onStoreItemUpdateReceived.bind(this));
        }

        if (!this.consumers.contains(consumer)) {
            this.consumers.push(consumer);
        }

        const parent = this;

        return {
            closed: false,
            unsubscribe() {
                if (this.closed) return;
                this.closed = true;
                parent.consumers.remove(consumer);

                // No more subscriptions, close graphql subscription
                if (!parent.consumers.length) {
                    parent.subscription?.unsubscribe();
                }
            }
        };
    }

    public validate(message: FetchResult<StoreItemStockUpdateSubscription, Record<string, any>, Record<string, any>>): boolean {
        if (message.errors) {
            message.errors.forEach(console.error.bind(console));
            return false;
        }

        if (!message.data || !message.data.storeItemStockUpdate) {
            console.error('Message received with no data.');
            return false;
        }

        return true;
    }

    private onStoreItemUpdateReceived(message: FetchResult<StoreItemStockUpdateSubscription, Record<string, any>, Record<string, any>>) {
        if (!this.validate(message)) {
            return;
        }
        this.consumers.forEach(x => message.data!.storeItemStockUpdate!.forEach(d => x(d)));
    }
}

export default new StoreItemsSubscriptionStore();