import { IComputedValue, IObservableValue, autorun, observe } from "mobx";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

export function useAutorun(fn: () => void, deps: any[] = []) {
    const autorunRef = useRef<() => void>();

    useEffect(() => {
        autorunRef.current = fn;
    }, [fn]);

    useEffect(() => {
        if (autorunRef.current) {
            return autorun(() => {
                autorunRef.current!();
            });
        }
    }, deps);
}

export function useObservable<T>(value: IObservableValue<T> | IComputedValue<T>) {
    const [state, setState] = useState(value.get());

    useEffect(() => {
        return observe(value, (v) => {
            setState(v.newValue as T);
        });
    }, [value]);

    return state;
}

export function useObserver<T>(fn: () => T) {
    const [state, setState] = useState<T>(fn());

    useLayoutEffect(() => {
        return autorun(() => {
            const newState = fn();
            setState(() => newState);
        });
    }, []);

    return state as T;
}
