export type PlatformOS = "Windows" | "macOS" | "Android" | "iOS" | "Unknown";

class Platforms {
    public getOS(): PlatformOS {
        const userAgent = window.navigator.userAgent;
        let os: PlatformOS = "Unknown";

        if (userAgent.includes("Win")) {
            os = "Windows";
        } else if (userAgent.includes("Mac")) {
            os = "macOS";
        } else if (userAgent.includes("Android")) {
            os = "Android";
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
            os = "iOS";
        }

        return os;
    }

    public isWindows(): boolean {
        return this.getOS() === "Windows";
    }

    public isMacOS(): boolean {
        return this.getOS() === "macOS";
    }
}

export default new Platforms();
