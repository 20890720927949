import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type QueryConsumer = (query: URLSearchParams) => void;

const UrlUtils = {
    usePushQuery: () => {
        const location = useLocation();
        const navigate = useNavigate();
        return useCallback((consumer: QueryConsumer) => {
            const query = new URLSearchParams(location.search);
            consumer(query);
            const queryStr = query.toString();
            if (queryStr) {
                navigate(`${location.pathname}?${queryStr}`);
            } else {
                navigate(`${location.pathname}`);
            }
        }, [location]);
    }
}

export default UrlUtils;