import styled from "styled-components";
import Input from "antd/lib/input";
import Form from "antd/lib/form";
import Button from "antd/lib/button";

export const HeaderMega = styled("header")`
    max-width: 90vw;
    margin-bottom: 40px;
    display: inline-block;
`;

export const TitleMega = styled("h1")`
    font-size: 6.5rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 6px;
    line-height: 1.16;
    margin: 0;
    margin-bottom: 60px;
    padding: 0;

    @media screen and (max-width: 991px) {
        font-size: 4.5rem;
    }
`;

export const TitleMegaSub = styled("h2")`
    font-size: 1.5rem;
    color: #a453f6;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 1.16;
    margin-bottom: 20px;
    margin-top: -20px;
`;

export const TitleLarge = styled(TitleMega)`
    font-size: 4.5rem;
    @media screen and (max-width: 991px) {
        font-size: 3.5rem;
    }
`;

export const Divider = styled("div")`
    max-width: calc(100% + 40px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;

    @media screen and (max-width: 991px) {
        img {
            max-width: 700px;
        }
    }
`;

export const LinkContainer = styled("div")`
    color: #8348bd;
    margin-bottom: 24px;
`;

export const Link = styled("a")`
    color: #dac1f2;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    position: relative;
    backface-visibility: hidden;
    font-size: 0%.8;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
        color: #e9e5d9;

        &:after {
            background: #7d7663;
            width: 100%;
        }
    }

    &:before {
        content: "";
        display: block;
        position: absolute;
        bottom: -4px;
        left: 50%;
        height: 1px;
        width: 100%;
        transform: translateX(-50%);
        background: #444036;
        transition-duration: .2s;
        transition-timing-function: cubic-bezier(.4,0,.2,1);
    }

    &:after {
        width: 10%;
        content: "";
        display: block;
        position: absolute;
        bottom: -4px;
        left: 50%;
        height: 1px;
        width: 100%;
        transform: translateX(-50%);
        background: #444036;
        transition-duration: .2s;
        transition-timing-function: cubic-bezier(.4,0,.2,1);
    }
`;

export const BackgroundBlur = styled("div")`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    filter: blur(4px);

    img {
        width: 100%;
    }
`;

export const DownloadButton = styled(Button)`
    padding: 30px 35px;
    min-width: 280px;
    max-width: 100%;
    margin: 54px 0;
    background: linear-gradient(180deg,#a453f6 0,#663399);
    font-size: 2rem;
    font-weight: 700;
    color: #f7cdfa;
    letter-spacing: 3px;
    text-transform: uppercase;
    cursor: pointer;
    line-height: 1;
    border: none;
    position: relative;
    z-index: 1;
    box-shadow: 0 0 25px rgba(0,0,0,.11);
    transition: color .2s;
    height: unset;

    &:hover {
        &:before {
            opacity: .8;
        }
    }

    &:before {
        top: 4px;
        left: 4px;
        right: 4px;
        bottom: 4px;
        content: "";
        display: block;
        position: absolute;
        background: #111;
        opacity: 1;
        z-index: -1;
        transition: opacity .2s ease-in-out;
    }

    &:hover {
        color: #f9cdfa;
    }

    &:hover, &:active, &:focus {
        background: linear-gradient(180deg,#a453f6 0,#663399);
    }

    &:active, &:focus {
        color: #f7cdfa;
    }
`;

export const SceneForm = styled(Form)`
    max-width: 100%;;
` as typeof Form;

export const InputMega = styled(Input)`
    width: 458px;
    max-width: 100%;
    margin: 26px auto;
    text-align: center;
    font-size: 1.8rem;
    border-color: #a453f6;
    display: block;
    border-top: 0;
    border-right: 0;
    border-left: 0;

    &::placeholder {
        color: #f7cdfa;
    }

    &:hover, &:focus, &:active {
        border-top: 0;
        border-right: 0;
        border-left: 0;
        box-shadow: none;
    }

    .ant-form-item-has-error & {
        &:hover, &:focus, &:active {
            box-shadow: none;
        }
    }

    &:focus, &:active {
        border-color: #ba79fc;
    }
`;

export const InputLarge = styled(InputMega)`
    width: 400px;
    font-size: 1.4rem;
`

export const SubmitButton = styled(DownloadButton)`
    margin: 30px 0 40px 0;
`;
SubmitButton.defaultProps = {
    htmlType: "submit",
}

export const ProgressStepsContainer = styled("div")`
    margin: 40px auto;
`;
