import { v4 as uuid } from 'uuid';

type HtmlFormInputDictionary = { [name: string]: HtmlInputManager };
type HtmlInputKey = 'type' | 'value' | 'placeholder';

export class HtmlInputManager {
    private readonly input: HTMLInputElement;

    public get name(): string {
        return this.input.name;
    }

    constructor(name: string) {
        this.input = document.createElement("input");
        this.input.name = name;
    }

    public get(attribute: HtmlInputKey): string {
        return this.input[attribute];
    }

    public set(attribute: HtmlInputKey, value: string): HtmlInputManager {
        this.input[attribute] = value;
        return this;
    }

    public render(): HTMLInputElement {
        return this.input;
    }
}

export class HtmlFormManager {
    private readonly form: HTMLFormElement;
    private readonly inputs: HtmlFormInputDictionary = {};

    public get id(): string {
        return this.form.id;
    }

    public get method(): string {
        return this.form.method;
    }
    public set method(val: string) {
        this.form.method = val;
    }

    public get action(): string {
        return this.form.action;
    }
    public set action(val: string) {
        this.form.action = val;
    }

    constructor(method: string, action: string) {
        this.form = document.createElement('form');
        this.form.id = uuid();
        this.form.method = method;
        this.form.action = action;
        this.form.style.display = "none";
        this.form.style.opacity = "0";
    }

    public createInput(name: string): HtmlInputManager {
        this.inputs[name] = new HtmlInputManager(name);
        return this.inputs[name];
    }

    public getInput(name: string): HtmlInputManager {
        return this.inputs[name];
    }

    public getOrCreateInput(name: string): HtmlInputManager {
        if (!this.inputs[name]) {
            this.inputs[name] = new HtmlInputManager(name);
        }
        return this.inputs[name];
    }

    public render(): HTMLFormElement {
        this.form.innerHTML = '';
        Object.values(this.inputs).forEach(input => {
            this.form.appendChild(input.render());
        });
        return this.form;
    }

    public submit(): void {
        this.form.submit();
    }
}

class HtmlFormUtils {
    private readonly root: HTMLDivElement;

    constructor() {
        this.root = document.createElement("div");
        document.body.appendChild(this.root);
    }

    public create(method: string, action: string): HtmlFormManager {
        return new HtmlFormManager(method, action);
    }

    public createMounted(method: string, action: string): HtmlFormManager {
        const mgr = this.create(method, action);
        this.mount(mgr);
        return mgr;
    }

    public mount(formManager: HtmlFormManager): void {
        this.root.appendChild(formManager.render());
    }

    public unmount(id: string): void {
        const el = document.getElementById(id);
        if (!el) return;
        this.root.removeChild(el);
    }
}

export default new HtmlFormUtils();