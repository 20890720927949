import { ApolloClient, ApolloLink, ApolloQueryResult, createHttpLink, DocumentNode, FetchResult, InMemoryCache, NetworkStatus, NormalizedCacheObject, Observable, OperationVariables } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Env from 'env';
import Pusher from 'pusher-js';
import PusherLink from 'services/realtime/PusherLink';

class GraphQL {
    public readonly client: ApolloClient<NormalizedCacheObject>;

    constructor() {
        const httpLink = createHttpLink({
            uri: `${Env.API_URL}/graphql`,
        });

        const authLink = setContext((_, { headers }) => {
            // get the authentication token from local storage if it exists
            const token = localStorage.getItem('token');
            // return the headers to the context so httpLink can read them
            return {
                headers: {
                    ...headers,
                    authorization: token ? `Bearer ${token}` : "",
                }
            }
        });

        const pusherLink = new PusherLink({
            pusher: new Pusher(Env.PUSHER_PUBLIC_KEY, {
                cluster: Env.PUSHER_CLUSTER,
                forceTLS: true,
                authEndpoint: `${Env.API_URL}/graphql/subscriptions/auth`,
            })
        });

        this.client = new ApolloClient({
            link: ApolloLink.from([
                authLink,
                pusherLink as ApolloLink,
                httpLink
            ]),
            cache: new InMemoryCache()
        });
    }

    public async query<T = any, TVariables extends OperationVariables = OperationVariables>(node: DocumentNode, defaut: T): Promise<ApolloQueryResult<T>> {
        try {
            return await this.client.query<T, TVariables>({
                query: node
            });
        } catch (e) {
            return {
                data: defaut,
                loading: false,
                networkStatus: NetworkStatus.error
            };
        }
    }

    public async mutate<T = any, TVariables extends OperationVariables = OperationVariables>(node: DocumentNode, defaut?: T): Promise<FetchResult<T>> {
        try {
            return await this.client.mutate<T, TVariables>({
                mutation: node
            });
        } catch (e) {
            return {
                data: defaut
            };
        }
    }

    public subscribe<T = any, TVariables extends OperationVariables = OperationVariables>(node: DocumentNode): Observable<FetchResult<T>> {
        return this.client.subscribe<T, TVariables>({
            query: node
        });
    }
}

export default new GraphQL();