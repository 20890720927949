import { Card, Tag, Typography } from "antd";
import useUser from "services/hooks/useUser";
import withGuard from "components/Guards/withGuard";
import PincodeGuard from "components/Guards/PincodeGuard";
import ChangeEmailForm from "./ChangeEmailForm";
import useFlashValue from "services/hooks/useFlashValue";
import { useMemo } from "react";
import type { CardSize } from "antd/lib/card/Card";

interface Props {
    size?: CardSize;
}

function ManageEmailWidget({ size }: Props) {
    const [user] = useUser();
    const [showSuccess, flashSuccess] = useFlashValue(false, 10000);

    const content = useMemo(() => {
        if (showSuccess) {
            return <SuccessMessage email={user?.email || ""} />;
        }
        if (user?.isEmailVerified) {
            return (
                <>
                    <div style={{ marginBottom: 20 }}>
                        <Typography.Text>
                            <strong>Current:</strong> {user.email} <Tag color="green">Verified</Tag> 
                        </Typography.Text>
                    </div>

                    {withGuard(PincodeGuard, { scope: "change-email" }, (props) => {
                        return (
                            <>
                                <Typography.Title level={5}>
                                    Enter a new email. We'll send a pincode to it to verify your ownership.
                                </Typography.Title>
                                <ChangeEmailForm
                                    {...props}
                                    onSuccess={() => flashSuccess(true)}
                                />
                            </>
                        );
                    })}
                </>
            );
        }
        return <ChangeEmailForm onSuccess={() => flashSuccess(true)} />;
    }, [user, showSuccess]);

    return (
        <Card
            size={size}
            title={!user?.isEmailVerified ? "Change or Verify your Email" :
                <>Change Email</>}
        >
            {content}
        </Card>
    )
};

function SuccessMessage({ email }: { email: string }) {
    return (
        <Typography.Title level={5}>
            <Typography.Text type="success" style={{ marginRight: 4 }}>
                Success!
            </Typography.Text>
            Email changed to <Typography.Text code>{email}</Typography.Text>
        </Typography.Title>
    );
}

export default ManageEmailWidget;
