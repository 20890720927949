import { makeAutoObservable, runInAction, toJS } from "mobx";
import { useObserver } from "services/helpers/mobxHelpers";

interface PurchaseGoldContext {
    visible: boolean;
    missingGold: number;
}

export type OpenPurchaseGoldModalOptions = Partial<Omit<PurchaseGoldContext, "visible">>;

const context = makeAutoObservable<PurchaseGoldContext>({
    visible: false,
    missingGold: 0,
}, undefined, { deep: true });

const usePurchaseGoldModel = () => {
    return useObserver(() => {
        return {
            context: toJS(context),
            open: (ctx?: OpenPurchaseGoldModalOptions) =>
                runInAction(() => {
                    context.visible = true;
                    if (ctx) {
                        context.missingGold = ctx.missingGold ?? 0;
                    }
                }),
            close: () => runInAction(() => {
                context.visible = false;
                context.missingGold = 0;
            }),
        };
    });
}

export default usePurchaseGoldModel;
