import Alert from "antd/lib/alert";
import Button from "antd/lib/button/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import { Navigate, useSearchParams } from "react-router-dom";
import { usePasswordResetMutation } from "services/api/GraphqlTypes";
import { validationRules } from "services/helpers/validationRules";
import useFlashValue from "services/hooks/useFlashValue";

function ResetPasswordForm() {
    const [submit, { error, loading }] = usePasswordResetMutation();
    const [showSuccess, setShowSuccess] = useFlashValue(false, 30000);
    const [params] = useSearchParams();
    const token = params.get("token");

    if (!token) {
        return <Navigate to="/forgot" />
    }

    return (
        <Form
            onFinish={data => {
                submit({
                    variables: {
                        token,
                        password: data.password
                    }
                }).then((r) => setShowSuccess(!!r.data?.passwordReset));
            }}
        >
            <Form.Item
                name="password"
                rules={validationRules.password}
            >
                <Input.Password
                    placeholder="New Password"
                    type="password"
                />
            </Form.Item>

            <Form.Item
                name="confirm-password"
                rules={[
                    { required: true, message: 'Please confirm your password' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The passwords you entered do not match'));
                        },
                    }),
                ]}
            >
                <Input.Password
                    placeholder="Confirm Password"
                    type="password"
                />
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                    style={{ marginRight: 16 }}
                >
                    Submit
                </Button>
            </Form.Item>

            {error && (
                <Form.Item>
                    <Alert message={error.message} type="error" />
                </Form.Item>
            )}

            {showSuccess && (
                <Form.Item>
                    <Alert
                        message="Your password was changed."
                        type="success"
                        action={(
                            <Button
                                type="link"
                                size="small"
                                href="/account"
                            >
                                Login
                            </Button>
                        )}
                    />
                </Form.Item>
            )}
        </Form>
    )
}

export default ResetPasswordForm;
