import Env from "env";
import { useGetHighscoresBaseQuery, useGetHighscoreSkillQuery } from "services/api/GraphqlTypes";
import useSearchQuery from "services/hooks/useSearchQuery";
import StringUtils from "services/utils/StringUtils";
import HighscoreTable from "./HighscoreTable";
import { useMatch } from "react-router-dom";

const HighscoreSkillsTableRoute: React.FC = () => {
    const query = useSearchQuery();
    const match = useMatch("/highscores/s/:name");
    const { data: baseData } = useGetHighscoresBaseQuery();

    const gameMode = query.get("mode") || baseData?.gameModes[0].name || "normal";
    const skill = match?.params.name || baseData?.gameSkills[0].name || "";
    const search = query.get("search") || undefined;
    const page = query.get("page") || undefined;

    const { data, loading } = useGetHighscoreSkillQuery({
        variables: {
            perPage: Env.HIGHSCORES_PER_PAGE,
            gameMode,
            name: skill,
            search: search,
            pageToken: page
        }
    });

    return (
        <HighscoreTable
            title={StringUtils.ucWords(skill)}
            data={data?.highscoreSkill?.data || []}
            pagination={data?.highscoreSkill.paginatorInfo}
            loading={loading}
        />
    );
};

export default HighscoreSkillsTableRoute;
