import Card, { CardProps } from "antd/lib/card";
import ResetPasswordForm from "components/Forgot/ResetPasswordForm";
import ContentPageLayout from "components/Layouts/ContentPageLayout";

function ResetPassword() {
    return (
        <ContentPageLayout
            pageHeader="Reset Password"
        >
            <Layout title="Enter a new password">
                <ResetPasswordForm />
            </Layout>
        </ContentPageLayout>
    );
}

function Layout({ children, ...props }: CardProps) {
    return (
        <Card {...props}>
            <div style={{ maxWidth: 600 }}>
                {children}
            </div>
        </Card>
    )
}

export default ResetPassword;
