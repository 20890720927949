import { Col, Form, Menu, Row, Select, Spin } from "antd";
import Search from "antd/lib/input/Search";
import HighscoreMiscTableRoute from "components/Highscores/HighscoreMiscTableRoute";
import HighscoreSkillsTableRoute from "components/Highscores/HighscoreSkillsTableRoute";
import ContentPageLayout from "components/Layouts/ContentPageLayout";
import { useEffect, useMemo } from "react";
import { Link, Route, Routes, useLocation, useNavigate, useMatch, matchPath, useResolvedPath } from "react-router-dom";
import { useGetHighscoresBaseQuery } from "services/api/GraphqlTypes";
import useSearchQuery from "services/hooks/useSearchQuery";
import StringUtils from "services/utils/StringUtils";


const routePath = "/highscores";
const makeCatPath = (curUrl: string, name: string) => `${curUrl}/c/${name}`;
const makeSkillPath = (curUrl: string, name: string) => `${curUrl}/s/${name}`;

const withQuery = (path: string, query: URLSearchParams) => `${path}${query.toString() ? `?${query.toString()}` : ""}`;

function Highscores() {
    const { data, loading } = useGetHighscoresBaseQuery();

    const gameModes = data?.gameModes || [];
    const gameSkills = data?.gameSkills || [];
    const miscCategories = data?.highscoreMiscCategories || [];

    const location = useLocation();
    const navigate = useNavigate();
    const query = useSearchQuery();

    const routesToMatch = useMemo(() => {
        return miscCategories
            .map(x => makeCatPath(routePath, x.name))
            .concat(gameSkills.map(x => makeSkillPath(routePath, x.name)));
    }, [data]);

    // const matchedPath = useRouteMatch(routesToMatch)?.path;
    const matchedPath = useMemo(() => {
        return routesToMatch
            .map(r => matchPath(r, location.pathname))
            .filter(x => x)
            .first()?.pathname;
    }, [location.pathname]);

    useEffect(() => {
        if (loading) {
            return;
        }
        const path = location.pathname;
        if (path.indexOf("/c/") < 0 && path.indexOf("/s/") < 0) {
            navigate(`${routePath}/c/${miscCategories[0]?.name}`);
        }
    }, [loading, data, location, navigate, routePath]);

    return (
        <ContentPageLayout
            pageHeader="Highscores"
        >
            <Row justify="end">
                <Col>
                    <Search
                        placeholder="Search username..."
                        onSearch={value => {
                            const newQuery = new URLSearchParams(query);
                            if (value) {
                                newQuery.set("search", value);
                            } else {
                                newQuery.delete("search");
                            }
                            navigate(`${location.pathname}?${newQuery.toString()}`);
                        }}
                        allowClear
                        enterButton
                    />
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                <Col span={24} md={5}>
                    <Spin spinning={loading}>
                        <Form layout="vertical">
                            <Form.Item
                                label="Game Mode"
                            >
                                <Select
                                    value={StringUtils.ucWords(query.get("mode") || "normal")}
                                    style={{ width: "100%" }}
                                    onChange={value => {
                                        const newQuery = new URLSearchParams(query);
                                        newQuery.set("mode", value);
                                        navigate(`${location.pathname}?${newQuery.toString()}`);
                                    }}
                                    options={gameModes.map(x => ({
                                        label: StringUtils.ucWords(x.name.replaceAll("_", " ")),
                                        value: x.name
                                    }))}
                                />
                            </Form.Item>
                        </Form>

                        <Menu
                            theme="light"
                            mode="inline"
                            selectedKeys={matchedPath ? [matchedPath] : []}
                            items={[
                                {
                                    type: "group",
                                    label: "Categories",
                                    children: miscCategories.map(x => ({
                                        key: makeCatPath(routePath, x.name),
                                        label: (
                                            <Link to={withQuery(makeCatPath(routePath, x.name), query)}>
                                                {StringUtils.ucWords(x.name)}
                                            </Link>
                                        )
                                    }))
                                },
                                {
                                    type: "group",
                                    label: "Skills",
                                    children: gameSkills.map(x => ({
                                        key: makeSkillPath(routePath, x.name),
                                        label: (
                                            <Link to={withQuery(makeSkillPath(routePath, x.name), query)}>
                                                {StringUtils.ucWords(x.name)}
                                            </Link>
                                        )
                                    }))
                                }
                            ]}
                        />
                    </Spin>
                </Col>

                <Col span={24} md={19}>
                    {!loading && (
                        <Routes>
                            <Route path="/c/:name" element={<HighscoreMiscTableRoute />} />
                            <Route path="/s/:name" element={<HighscoreSkillsTableRoute />} />
                        </Routes>
                    )}
                </Col>
            </Row>
        </ContentPageLayout>
    );
}

export default Highscores;