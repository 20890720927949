import { IUserRanksImpl, UserRanks } from "./UserRankData";

const enchantRankCosts = {
    sapphire: 1300,
    ruby: 6500,
    diamond: 13000,
    dragonstone: 32500,
    onyx: 65000,
    hydrix: 130000
};

interface IUserRankData {
    discordRank: boolean;
    iconRankInGame: boolean;
    commandBank: boolean;
    commandYell: boolean;
    cooldownBetweenYells: number | string;
    bonusExperience: number | string;
    bonusEnchantOrbDropCount: number | string;
    bonusMorphExperience: number | string;
    bonusMorphDamage: number | string;
    bonusOresFromAfkMines: number | string;
    bankSpace: number | string;
    bonusGlyphSuccessChance: number | string;
    bonusPetDropRate: number | string;
    bonusFoodHealing: number | string;
    extraGlyphSlot: boolean;
}

type TRanks = keyof typeof enchantRankCosts;

const enchantRanks: UserRanks<TRanks, IUserRankData> = {
    sapphire: {
        discordRank: true,
        iconRankInGame: true,
        commandBank: false,
        commandYell: false,
        cooldownBetweenYells: 0,
        bonusExperience: 0,
        bonusEnchantOrbDropCount: 0,
        bonusMorphExperience: "3%",
        bonusMorphDamage: 0,
        bonusOresFromAfkMines: 0,
        bankSpace: 375,
        bonusGlyphSuccessChance: "2%",
        bonusPetDropRate: 0,
        bonusFoodHealing: 0,
        extraGlyphSlot: false
    }
} as any;

enchantRanks.ruby = {
    ...enchantRanks.sapphire,
    commandBank: true,
    bonusExperience: "10%",
    bonusEnchantOrbDropCount: "2%",
    bonusMorphExperience: "6%",
    bankSpace: 400,
    bonusGlyphSuccessChance: "5%",
    bonusPetDropRate: "8%",
    extraGlyphSlot: true
};

enchantRanks.diamond = {
    ...enchantRanks.ruby,
    commandYell: true,
    cooldownBetweenYells: "60s",
    bonusExperience: "15%",
    bonusEnchantOrbDropCount: "4%",
    bonusMorphExperience: "9%",
    bonusMorphDamage: "2%",
    bankSpace: 425,
    bonusGlyphSuccessChance: "10%",
    bonusPetDropRate: "16%",
    bonusFoodHealing: "7%"
};

enchantRanks.dragonstone = {
    ...enchantRanks.diamond,
    cooldownBetweenYells: "30s",
    bonusExperience: "20%",
    bonusEnchantOrbDropCount: "6%",
    bonusMorphExperience: "15%",
    bonusMorphDamage: "5%",
    bonusOresFromAfkMines: 1,
    bankSpace: 450,
    bonusGlyphSuccessChance: "15%",
    bonusPetDropRate: "25%",
    bonusFoodHealing: "14%"
};

enchantRanks.onyx = {
    ...enchantRanks.dragonstone,
    cooldownBetweenYells: "10s",
    bonusExperience: "25%",
    bonusEnchantOrbDropCount: "8%",
    bonusMorphExperience: "20%",
    bonusMorphDamage: "10%",
    bankSpace: 475,
    bonusGlyphSuccessChance: "20%",
    bonusPetDropRate: "40%",
    bonusFoodHealing: "20%"
};

enchantRanks.hydrix = {
    ...enchantRanks.onyx,
    cooldownBetweenYells: "5s",
    bonusExperience: "30%",
    bonusEnchantOrbDropCount: "10%",
    bonusMorphExperience: "25%",
    bonusMorphDamage: "20%",
    bankSpace: 500,
    bonusGlyphSuccessChance: "30%",
    bonusPetDropRate: "60%",
    bonusFoodHealing: "30%"
};

const userRankDataEnchant: IUserRanksImpl<IUserRankData, TRanks> = {
    labels: {
        discordRank: "Exclusive Discord Rank",
        iconRankInGame: "Icon rank In-game",
        commandBank: "Access to command ::bank",
        commandYell: "Access to command: ::yell",
        cooldownBetweenYells: "Cooldown Between Yells",
        bonusExperience: "Bonus Experience",
        bonusEnchantOrbDropCount: "Bonus Enchant Orb Drop Count",
        bonusMorphExperience: "Bonus Morph Experience",
        bonusMorphDamage: "Bonus Morph Damage",
        bonusOresFromAfkMines: "Bonus Ores from AFK Mines",
        bankSpace: "Bank Space",
        bonusGlyphSuccessChance: "Bonus Success Chance When Upgrading Glyphs",
        bonusPetDropRate: "Bonus PET drop rate boost",
        bonusFoodHealing: "Food Healing Bonus",
        extraGlyphSlot: "Unlock 4th Glyph Slot"
    },
    ranks: enchantRanks,
    rankCosts: enchantRankCosts
}

export default userRankDataEnchant;