import Env from "env";

function trimStartingSlash(path: string) {
    return path[0] == "/" ? path.slice(1) : path;
}

export function resolveExternalUrl(path: string) {
    return `${Env.APP_URL}/${trimStartingSlash(path)}`;
}

export default function resolvePublicUrl(path: string) {
    return `${process.env.PUBLIC_URL}/${trimStartingSlash(path)}`;
}