import { Button } from 'antd';
import GoldIcon from 'components/Common/GoldIcon';
import PurchaseGoldModal from 'components/Store/PurchaseGoldModal/PurchaseGoldModal';
import usePurchaseGoldModal, { OpenPurchaseGoldModalOptions } from 'components/Store/PurchaseGoldModal/usePurchaseGoldModal';
import React, { useMemo } from 'react';

interface Props extends Partial<OpenPurchaseGoldModalOptions> {
    children?: React.ReactNode;
    Component?: React.ComponentType<{
        onClick?: (_: any) => any,
        children?: React.ReactNode,
    }>;
}

function PurchaseGoldButton({ children, Component, ...context }: Props) {
    const { open: openPurchaseGoldModal } = usePurchaseGoldModal();

    const content = useMemo(() => {
        return children ?? <GoldIcon suffix="Topup your account" />;
    }, [children]);

    return (
        <>
            <div className="purchase-gold-button-wrapper">
                {Component ? (
                    <Component
                        onClick={() => openPurchaseGoldModal(context)}
                    >
                        {content}
                    </Component>
                ) : (
                    <Button
                        className="purchase-gold-button"
                        shape="round"
                        onClick={() => openPurchaseGoldModal(context)}
                    >
                        {content}
                    </Button>
                )}
            </div>
            <PurchaseGoldModal />
        </>
    )
}

export default PurchaseGoldButton;
