import MailOutlined from "@ant-design/icons/MailOutlined";
import Alert from "antd/lib/alert";
import Button from "antd/lib/button/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import { useForgotUsernameMutation } from "services/api/GraphqlTypes";
import useFlashValue from "services/hooks/useFlashValue";

function ForgotUsernameForm() {
    const [submit, { error, loading }] = useForgotUsernameMutation();
    const [showSuccess, setShowSuccess] = useFlashValue(false, 30000);

    return (
        <Form
            onFinish={data => {
                submit({
                    variables: {
                        email: data.email
                    }
                }).then((r) => setShowSuccess(!!r.data?.forgotUsername));
            }}
        >
            <Form.Item
                name="email"
                rules={[{
                    required: true,
                    type: 'email',
                    message: 'Please enter a valid email!',
                }]}
                help="We will send all usernames associated with this email"
                style={{ marginBottom: "calc(24px + 22px)" }}
            >
                <Input
                    placeholder="Email"
                    autoComplete="email"
                    prefix={<MailOutlined />}
                />
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                    style={{ marginRight: 16 }}
                >
                    Send Username
                </Button>
            </Form.Item>

            {error && (
                <Form.Item>
                    <Alert message={error.message} type="error" />
                </Form.Item>
            )}

            {showSuccess && (
                <Form.Item>
                    <Alert message="If an account with this email exists, you will receive an email with your usernames." type="success" />
                </Form.Item>
            )}
        </Form>
    )
}

export default ForgotUsernameForm;
