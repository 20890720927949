import { Space, Tooltip } from "antd";
import { PropsWithChildren, ReactNode, useMemo } from "react";
import { useGetHighscoresBaseQuery } from "services/api/GraphqlTypes";
import StringUtils from "services/utils/StringUtils";

type IPlayerUsernameProps = PropsWithChildren<{
    mode?: string;
    rank?: string;
}>;

const PlayerUsername: React.FC<IPlayerUsernameProps> = ({ children, mode, rank }) => {
    const { data } = useGetHighscoresBaseQuery();
    const modes = data?.gameModes || [];

    return useMemo(() => {
        let render = (
            <span>{children}</span>
        );

        if ((mode && mode.toLowerCase() !== "normal") || (rank && rank.toLowerCase() !== "normal")) {
            let modeElement: ReactNode = null;
            if (mode && mode !== "normal") {
                const modeImage = modes.filter(x => x.name.toLowerCase() === mode.toLowerCase())[0]?.image;
                if (modeImage) {
                    modeElement = <img alt={mode} src={modeImage} />
                }
            }

            render = (
                <Space>
                    {modeElement && (
                        <Tooltip title={StringUtils.ucWords(`${mode} Player`)}>
                            {modeElement}
                        </Tooltip>
                    )}
                    
                    {rank && rank.toLowerCase() !== "normal" && (
                        <Tooltip title={StringUtils.ucWords(`${rank} Player`)}>
                            {<img alt={rank} src={`/images/ranks/${StringUtils.sanitize(rank)}.png`} />}
                        </Tooltip>
                    )}

                    {render}
                </Space>
            )
        }

        return render;
    }, [mode, rank, modes]);
}

export default PlayerUsername;