import React, { useMemo } from 'react';
import { Menu } from 'antd';
import { matchPath, useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

export type IRouteMenuItem = {
    label: string | React.ReactNode;
} & ({
    path: string;
    asPath?: string;
    onClick?: never;
    href?: never;
} | {
    path?: never;
    asPath?: never;
    onClick: Action;
    href?: never;
} | {
    path?: never;
    asPath?: never;
    onClick?: never;
    href: string;
});

interface Props {
    items: IRouteMenuItem[];
    style?: React.CSSProperties;
}

const RoutesMenu: React.FC<Props> = ({ items, style }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const matchedPaths = useMemo(() => {
        return items
            .filter(x => x.path)
            .map(x => {
                if (x.path === "/") {
                    return location.pathname;
                }
                const match = matchPath(location.pathname, x.path!);
                if (match) {
                    return x.path;
                }
                return undefined;
            })
            .filter(x => x) as string[];
    }, [items, location]);

    return (
        <Menu
            theme="dark"
            mode="horizontal"
            selectedKeys={matchedPaths}
            style={style}
            items={items.map((x, i) => {
                return {
                    key: x.path ?? x.href ?? i,
                    label: x.label,
                    onClick: () => {
                        if (x.onClick) {
                            x.onClick();
                        } else if (x.href) {
                            window.open(x.href, "_blank");
                        } else if (x.path) {
                            navigate(x.path)
                        }
                    }
                };
            })}
        />
    );
}

export default RoutesMenu;