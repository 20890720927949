import { Card, List } from "antd"
import { useGetTopMonthlyVotersQuery } from "services/api/GraphqlTypes";

interface IMonthlyVotesWidgetProps {

}

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

const MonthlyVotesWidget: React.FC<IMonthlyVotesWidgetProps> = () => {
    const now = new Date().getUTCMonth();
    let month = "period";

    try {
        month = monthNames[now];
    } catch {}

    const { data, loading } = useGetTopMonthlyVotersQuery();

    return (
        <Card
            size="small"
            title={`Top voters for ${month}`}
            loading={loading}
        >
            <List
                dataSource={data?.topMonthlyVoters || []}
                renderItem={(item, index) => (
                    <List.Item
                        actions={[item.votes]}
                    >
                        {(index+1)}. {item.name}
                    </List.Item>
                )}
            >
                </List>
        </Card>
    );
}

export default MonthlyVotesWidget;