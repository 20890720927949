import { IUserRanksImpl, UserRanks } from "./UserRankData";

const rebirthRankCosts = {
    reborn: 3250,
    gifted: 6500,
    blessed: 13000,
    hallowed: 32500,
    sanctified: 65000,
    divine: 130000,
    serenity: 325000,
    celestial: 650000,
    eternal: 1300000
};

interface IUserRankData {
    discordRank: boolean;
    iconRankInGame: boolean;
    commandBank: boolean;
    commandYell: boolean;
    commandFly: boolean;
    zoneGlobalBoss: boolean,
    bonusDroprateBoost: string | number,
    bonusDamageBoost: string | number,
    bonusCritChance: string | number,
    bonusCritDamage: string | number,
    bonusSoulsplit: string | number,
    bonusXpBoost: string | number,
    bonusPvmPoints: string | number,
    unlimitedPrayer: boolean,
    canGamble: boolean
}

type TRanks = keyof typeof rebirthRankCosts;

const rebirthRanks: UserRanks<TRanks, IUserRankData> ={
    reborn: {
        discordRank: true,
        iconRankInGame: true,
        commandBank: true,
        commandYell: true,
        commandFly: false,
        zoneGlobalBoss: true,
        bonusDroprateBoost: "5%",
        bonusDamageBoost: "",
        bonusCritChance: "",
        bonusCritDamage: "",
        bonusSoulsplit: "",
        bonusXpBoost: "",
        bonusPvmPoints: 0,
        unlimitedPrayer: false,
        canGamble: false
    }
} as any;

rebirthRanks.gifted = {
    ...rebirthRanks.reborn,
    commandFly: true,
    bonusDroprateBoost: "10%",
    bonusSoulsplit: "4%"
}

rebirthRanks.blessed = {
    ...rebirthRanks.reborn,
    commandFly: true,
    bonusDroprateBoost: "15%",
    bonusSoulsplit: "8%",
    bonusPvmPoints: 1
}

rebirthRanks.hallowed = {
    ...rebirthRanks.reborn,
    commandFly: true,
    bonusDroprateBoost: "20%",
    bonusPvmPoints: 1,
    bonusCritChance: "5%",
    bonusCritDamage: "5%",
    bonusSoulsplit: "12%",
    canGamble: true
}

rebirthRanks.sanctified = {
    ...rebirthRanks.reborn,
    commandFly: true,
    bonusDroprateBoost: "30%",
    bonusDamageBoost: "10%",
    bonusPvmPoints: 1,
    bonusCritChance: "7.5%",
    bonusCritDamage: "7.5%",
    bonusSoulsplit: "16%",
    bonusXpBoost: "25%",
    unlimitedPrayer: true,
    canGamble: true
}

rebirthRanks.divine = {
    ...rebirthRanks.reborn,
    commandFly: true,
    bonusDroprateBoost: "40%",
    bonusDamageBoost: "20%",
    bonusPvmPoints: 2,
    bonusCritChance: "10%",
    bonusCritDamage: "10%",
    bonusSoulsplit: "20%",
    bonusXpBoost: "40%",
    unlimitedPrayer: true,
    canGamble: true
}

rebirthRanks.serenity = {
    ...rebirthRanks.reborn,
    commandFly: true,
    bonusDroprateBoost: "50%",
    bonusDamageBoost: "20%",
    bonusPvmPoints: 2,
    bonusCritChance: "15%",
    bonusCritDamage: "15%",
    bonusSoulsplit: "20%",
    bonusXpBoost: "40%",
    unlimitedPrayer: true,
    canGamble: true
}

rebirthRanks.celestial = {
    ...rebirthRanks.reborn,
    commandFly: true,
    bonusDroprateBoost: "60%",
    bonusDamageBoost: "30%",
    bonusPvmPoints: 2,
    bonusCritChance: "20%",
    bonusCritDamage: "20%",
    bonusSoulsplit: "20%",
    bonusXpBoost: "50%",
    unlimitedPrayer: true,
    canGamble: true
}

rebirthRanks.eternal = {
    ...rebirthRanks.reborn,
    commandFly: true,
    bonusDroprateBoost: "70%",
    bonusDamageBoost: "30%",
    bonusPvmPoints: 3,
    bonusCritChance: "25%",
    bonusCritDamage: "25%",
    bonusSoulsplit: "20%",
    bonusXpBoost: "50%",
    unlimitedPrayer: true,
    canGamble: true
}

const userRankDataRebirth: IUserRanksImpl<IUserRankData, TRanks> = {
    labels: {
        discordRank: "Exclusive Discord Rank",
        iconRankInGame: "Icon rank In-game",
        commandBank: "Access to command ::bank",
        commandYell: "Access to command: ::yell",
        commandFly: "Access to command: ::fly",
        zoneGlobalBoss: "Access to Zone Global Boss",
        bonusDroprateBoost: "Bonus Droprate Boost",
        bonusDamageBoost: "Bonus Damage Boost",
        bonusCritChance: "Bonus Critical Chance",
        bonusCritDamage: "Bonus Critical Damage",
        bonusSoulsplit: "Bonus Soul Split",
        bonusXpBoost: "Bonus XP Boost",
        bonusPvmPoints: "Bonus PVM Points",
        unlimitedPrayer: "Unlimited Prayer",
        canGamble: "Ability to Gamble"
    },
    ranks: rebirthRanks,
    rankCosts: rebirthRankCosts
}

export default userRankDataRebirth;