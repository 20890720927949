/* eslint no-extend-native: 0 */
declare global {
    interface Array<T> {
        remove(item: T): boolean;
        contains(item: T): boolean;
        containsWhere(selector: FuncOf<T, boolean>): boolean;
        orderBy(getter: (item: T) => any): Array<T>;
        orderByDescending(getter: (item: T) => any): Array<T>;
        distinct(): Array<T>;
        sum(getter?: FuncOf<T, number>): number;
        first(): T | undefined;
    }
}
Array.prototype.remove = function(item): boolean {
    const index = this.indexOf(item);
    if (index > -1) {
        this.splice(index, 1);
        return true;
    }
    return false;
}
Array.prototype.contains = function(item): boolean {
    return this.indexOf(item) > -1;
}
Array.prototype.containsWhere = function<T>(selector: FuncOf<T, boolean>): boolean {
    const self = this as Array<T>;
    return self.findIndex(selector) > -1;
}
Array.prototype.orderBy = function<T>(getter: (item: T) => any): Array<T> {
    return this.sort((a, b) => {
        const valA = getter(a);
        const valB = getter(b);
        if (valA > valB) {
            return 1;
        } else if (valA < valB) {
            return -1;
        }
        return 0;
    })
}
Array.prototype.orderByDescending = function<T>(getter: (item: T) => any): Array<T> {
    return this.sort((a, b) => {
        const valA = getter(a);
        const valB = getter(b);
        if (valA > valB) {
            return -1;
        } else if (valA < valB) {
            return 1;
        }
        return 0;
    })
}
Array.prototype.distinct = function(): Array<any> {
    return this.filter((value, index) => {
        return this.indexOf(value) === index;
    });
}
Array.prototype.sum = function<T>(getter: FuncOf<T, number> | undefined = undefined): number {
    const self = this as T[]
    let array = this as number[];
    if (getter) {
        array = self.map(x => getter(x));
    }
    return array.reduce((prev, cur) => prev + cur, 0);
}
Array.prototype.first = function<T>(): T | undefined {
    const self = this as Array<T>;
    return self[0];
}

export {};