class MathUtils {
    public round(number: number, decimals: number = 2): number {
        const mult = Math.pow(10, decimals);
        return Math.round(number * mult) / mult;
    }

    public roundToString(number: number, decimals: number = 2, alwaysShow: boolean = false): string {
        let rounded = this.round(number, decimals).toString();
        const split = rounded.split(".");
        if (alwaysShow && split.length < 2) {
            rounded += ".00";
        } else if (split.length == 2 && split[1].length < decimals) {
            for (let i = 0; i < decimals - split[1].length; i++) {
                rounded += "0"    
            }
        }
        return rounded;
    }
}

export default new MathUtils();