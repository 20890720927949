import { ReactElement, ReactNode, ValidationMap, WeakValidationMap } from "react";

interface FC<P = {}> {
    (props: P, context?: any): ReactElement<any, any> | null;
    propTypes?: WeakValidationMap<P>;
    contextTypes?: ValidationMap<any>;
    defaultProps?: Partial<P>;
    displayName?: string;
}

type GuardComponentType<P extends { children?: ReactNode }, C> = FC<P> & {
    useContext?: () => C;
}

function withGuard<GP extends { children?: ReactNode }, GC extends {}>(
    Guard: GuardComponentType<GP, GC>,
    guardProps: GP,
    Component: FC<GC>
) {
    function Child() {
        const context = (Guard.useContext?.() || {}) as GC;
        return <Component {...context} />;
    }
    return (
        <Guard {...guardProps}>
            <Child />
        </Guard>
    );
}

export default withGuard;
