import ContentPageLayout from 'components/Layouts/ContentPageLayout';
import ManageEmailWidget from 'components/Widgets/ManageEmailWidget/ManageEmailWidget';
import LoginWidget from 'components/Widgets/LoginWidget';
import React from 'react';
import useUser from 'services/hooks/useUser';
import ManagePasswordWidget from 'components/Widgets/ManagePasswordWidget/ManagePasswordWidget';
import { Col, Row } from 'antd/lib/grid';
import LogoutButton from 'components/Widgets/UserWidget/LogoutButton';
import Space from 'antd/lib/space';
import UserWidget from 'components/Widgets/UserWidget/UserWidget';

const Account: React.FC = () => {
    const [user] = useUser();
    const isLoggedIn = !!user;

    if (!isLoggedIn) {
        return (
            <ContentPageLayout
                pageHeader="Account"
            >
                <LoginSection />
            </ContentPageLayout>
        );
    }

    return (
        <ContentPageLayout
            pageHeader="Account"
        >
            <Row wrap gutter={[16, 16]} className="store">
                <Col
                    span={24}
                    lg={16}
                >
                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <ManageEmailWidget />
                        <ManagePasswordWidget />
                    </Space>
                </Col>

                <Col span={24} lg={{ span: 8, order: 2 }}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <UserWidget />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </ContentPageLayout>
    );
}

function LoginSection() {
    return (
        <Row>
            <Col span={8}>
                <LoginWidget />
            </Col>
        </Row>
    )
}

export default Account;