import { Col, Row } from 'antd/lib/grid';
import PageHeader from 'antd/lib/page-header';
import React from 'react';

interface Props {
    pageHeader?: React.ReactNode;
}

function ContentPageLayout({ children, pageHeader }: React.PropsWithChildren<Props>) {
    return (
        <Row justify="center">
            <Col className="content-page">
                {pageHeader ? <PageHeader title={pageHeader} ghost={false}>{children}</PageHeader> :
                    children}
            </Col>
        </Row>
    );
}

export default ContentPageLayout;