import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages';
import Store from './pages/store';
import Vote from './pages/vote';
import Highscores from 'pages/highscores';
import FlashQueryAnalytics from 'components/Helpers/FlashQueryAnalytics';
import FlashQueryNotification from 'components/Helpers/FlashQueryNotification';
import PlayNow from 'pages/playnow';
import GraphQL from 'services/api/GraphQL';
import { ApolloProvider } from "@apollo/client";
import { layoutMain } from 'layouts/LayoutMain';
import Account from 'pages/account';
import Forgot from 'pages/forgot';
import ResetPassword from 'pages/reset-password';

function App() {
    return (
        <BrowserRouter>
                <ApolloProvider
                    client={GraphQL.client}
                >
                    <FlashQueryAnalytics />
                    <FlashQueryNotification />

                    <Routes>
                        <Route path="/" element={layoutMain(Home)} />
                        <Route path="/store" element={layoutMain(Store)} />
                        <Route path="/vote" element={layoutMain(Vote)} />
                        <Route path="/highscores/*" element={layoutMain(Highscores)} />
                        <Route path="/account" element={layoutMain(Account)} />
                        <Route path="/forgot" element={layoutMain(Forgot)} />
                        <Route path="/reset-password" element={layoutMain(ResetPassword)} />
                        {/* No Layout */}
                        <Route path="/playnow/*" element={<PlayNow/>} />
                    </Routes>
                </ApolloProvider>
        </BrowserRouter>
    );
}

export default App;
