import { PropsWithChildren } from "react";

type IBlurOverlayProps = PropsWithChildren<{
    text: string;
    enabled: boolean;
}>;

const BluredOverlay: React.FC<IBlurOverlayProps> = ({ children, text, enabled }) => {
    return (
        <div
            style={{ position: "relative" }}
        >
            {children}
            {enabled && (
                <div
                    style={{
                        position: "absolute",
                        top: 0, right: 0, bottom: 0, left: 0,
                        backdropFilter: "blur(4px)",
                        overflow: "visible",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: 32
                    }}
                >
                    <span
                        style={{
                            textShadow: "0 0 4px #fff"
                        }}
                    >{text}</span>
                </div>
            )}
        </div>
    );
}

export default BluredOverlay;