import MailOutlined from "@ant-design/icons/MailOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import Alert from "antd/lib/alert";
import Button from "antd/lib/button/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import { useSendPasswordResetMutation } from "services/api/GraphqlTypes";
import { validationRules } from "services/helpers/validationRules";
import useFlashValue from "services/hooks/useFlashValue";

function ForgotPasswordForm() {
    const [submit, { error, loading }] = useSendPasswordResetMutation();
    const [showSuccess, setShowSuccess] = useFlashValue(false, 30000);

    return (
        <Form
            onFinish={data => {
                submit({
                    variables: {
                        email: data.email,
                        name: data.name,
                    }
                }).then((r) => setShowSuccess(!!r.data?.sendPasswordReset));
            }}
        >
            <Form.Item
                name="email"
                rules={validationRules.email}
            >
                <Input
                    placeholder="Email"
                    autoComplete="email"
                    prefix={<MailOutlined />}
                />
            </Form.Item>

            <Form.Item
                name="name"
                rules={validationRules.name}
            >
                <Input
                    placeholder="Username"
                    prefix={<UserOutlined />}
                />
            </Form.Item>

            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                    style={{ marginRight: 16 }}
                >
                    Reset Password
                </Button>
            </Form.Item>

            {error && (
                <Form.Item>
                    <Alert message={error.message} type="error" />
                </Form.Item>
            )}

            {showSuccess && (
                <Form.Item>
                    <Alert message="If an account with this email and username exists, you will receive an email to reset your password." type="success" />
                </Form.Item>
            )}
        </Form>
    )
}

export default ForgotPasswordForm;
