import { Content } from "antd/lib/layout/layout";
import Env from "env";
import { useMatch } from "react-router-dom";

function AppContent({ children }: React.PropsWithChildren<{}>) {
    const match = useMatch({
        path: "/",
        end: true,
    });

    const mainStyles: React.CSSProperties = match ? {} : {
        backgroundImage: `url(${Env.BACKGROUND_IMAGE})`,
        backgroundPosition: "top center",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#000",
        backgroundSize: "100%",
    };

    return (
        <Content
            className="height-relative"
            style={{
                ...mainStyles,
                overflow: "hidden"
            }}
        >
            {children}
        </Content>
    )
}

export default AppContent;