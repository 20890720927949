import LogoutOutlined from "@ant-design/icons/lib/icons/LogoutOutlined";
import Button from "antd/lib/button";
import { useLogoutMutation } from "services/api/GraphqlTypes";
import useUser from "services/hooks/useUser";

interface Props {
    style?: React.CSSProperties;
}

function LogoutButton({ style }: Props) {
    const [,, { onLogout }] = useUser();
    const [logoutMutation, { loading: logoutLoading }] = useLogoutMutation({
        onError: () => { },
        onCompleted: _ => onLogout()
    });

    return (
        <Button
            size="small"
            type="link"
            style={style}
            loading={logoutLoading}
            disabled={logoutLoading}
            onClick={() => logoutMutation()}
            icon={<LogoutOutlined />}
        >
            Logout
        </Button>
    );
}

export default LogoutButton;
