import { autorun, computed, makeObservable, observable, reaction, runInAction, toJS } from "mobx";
import GraphQL from "../api/GraphQL";
import { CartDocument, CartItem, CartQuery, StoreItem } from "../api/GraphqlTypes";
import UserStore from "./UserStore";
import "../extensions/index";
import ReactiveStoreItemsStore from "./StoreItemsStore/ReactiveStoreItemsStore";
import StoreAnalytics from "services/analytics/StoreAnalytics";

type CartUpdateMessage = {
    quantity: number;
} & ({
    storeItemId: number;
    storeItem?: never;
} | {
    storeItemId?: never;
    storeItem: StoreItem;
})

export class ShoppingCartStore {
    private readonly userStore: UserStore;

    public cartItems: CartItem[] = [];
    public loading: boolean = false;

    private reactiveStore: ReactiveStoreItemsStore | undefined;

    public get total(): number {
        return this.cartItems
            .filter(x => x?.storeItem)
            .sum(x => x.storeItem.price * x.quantity);
    }

    constructor(userStore: UserStore) {
        this.userStore = userStore;

        makeObservable(this, {
            cartItems: observable.deep,
            loading: observable,
            total: computed
        });

        autorun(() => {
            if (this.userStore.user) {
                setTimeout(() => this.refresh());
            } else {
                runInAction(() => this.cartItems = []);
            }
        });

        let disposer: Disposer;
        reaction(r => this.cartItems, (v, p, r) => {
            if (this.reactiveStore) {
                disposer();
                const old = this.reactiveStore;
                setTimeout(() => old.dispose());
            }
            
            this.reactiveStore = new ReactiveStoreItemsStore(v.map(x => x.storeItem));
            disposer = autorun(() => {
                toJS(this.reactiveStore?.storeItems ?? []);
                runInAction(() => {
                    this.cartItems.forEach(item =>
                        item.storeItem = this.reactiveStore!.storeItems.find(x => x.id === item.storeItem.id) || item.storeItem);
                });
            })
        });
    }

    public onItemUpdate(message: CartUpdateMessage) {
        let itemId = message.storeItemId || message.storeItem?.id;
        if (!itemId) {
            console.error("Item not found and not provided.");
            return;
        }

        const items = [ ...toJS(this.cartItems) ];
        const index = items.findIndex(x => x.storeItem.id == itemId);
        const item = items[index]?.storeItem || message.storeItem;
        
        runInAction(() => {
            if (message.quantity === 0) {
                if (index > -1) {
                    items.splice(index, 1);
                }
            } else if (index > -1) {
                items[index].quantity = message.quantity;
            } else {
                items.push({
                    storeItem: item!,
                    quantity: message.quantity,
                });
            }
            this.cartItems = items;
        });
    }

    public onEmptyCart() {
        StoreAnalytics.emptyCart(this.cartItems);
        runInAction(() => this.cartItems = []);
    }

    public find(id: number) {
        return this.cartItems.find(x => x.storeItem.id === id);
    }

    public async refresh(): Promise<void> {
        runInAction(() => this.loading = true);
        try {
            const result = await GraphQL.query<CartQuery>(CartDocument, { cart: [] });
            runInAction(() => this.cartItems = (result?.data?.cart || []) as CartItem[]);
        } catch (e) {
            console.error(e);
        }
        runInAction(() => this.loading = false);
    }
}
