import Env from "env";
import UserRankDataEnchant from "./UserRankDataEnchant";
import userRankDataRebirth from "./UserRankDataRebirth";

type UserRankLabels<T> = Record<keyof T, string>;
export type UserRanks<K extends string, T> = Record<K, T>;

export interface IUserRanksImpl<T, TRanks extends string> {
    rankCosts: Record<TRanks, number>;
    labels: UserRankLabels<T>;
    ranks: UserRanks<TRanks, T>;
}

interface IUserRanks<T, TRanks extends string> extends IUserRanksImpl<T, TRanks> {
    rankNames: string[];
}

let userRankData: IUserRanks<any, string> = UserRankDataEnchant as any;
if (Env.GAME_TYPE === "rebirth") {
    userRankData = userRankDataRebirth as any;
}

userRankData.rankNames =  Object.keys(userRankData.rankCosts).map(x => `${x[0].toUpperCase()}${x.substring(1)}`)

export default userRankData;