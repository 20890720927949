import { ReactNode } from "react";

interface IInfoPanel {
    key: string;
    title: ReactNode;
    description: ReactNode;
    thumbUrl: string;
}

interface IInfoSectionProps {
    heading: ReactNode;
    description: ReactNode;
    panels: IInfoPanel[];
}

const InfoSection: React.FC<IInfoSectionProps> = ({ heading, description, panels }) => {
    return (
        <section id="info">
            <div className="wrapper">

                <div className="text">
                    <h1>{heading}</h1>
                    <p className="body-text">
                        {description}
                    </p>
                </div>

                <div id="panels">
                    <div className="panels-container">

                        {panels.map(panel => {
                            return (
                                <div className="panel" key={panel.key}>
                                    <img
                                        alt="divider"
                                        src="https://cdn.battlerite.com/www3/images/static/bg/Seperator.png"
                                        className="divider"
                                    />
                                    <div className="panel-body">
                                        <div className="panel-content">
                                            <h3>{panel.title}</h3>
                                            <p>{panel.description}</p>
                                        </div>

                                        <div className="video-container">
                                            <div
                                                className="video-thumbnail"
                                                style={{
                                                    backgroundImage: `url(${panel.thumbUrl})`
                                                }}
                                            ></div>
                                        </div>

                                    </div>
                                </div>
                            );
                        })}

                    </div>
                </div>

            </div>
        </section>
    );
}

export default InfoSection;