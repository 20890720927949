import { Col, Row, Space } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import resolvePublicUrl from "../services/helpers/resolvePublicUrl";
import playFreeNowSrc from "assets/images/play_free_now.png";
import PlayButton from "components/Index/PlayButton";
import InfoSection from "components/Index/InfoSection";
import Random from "services/utils/Random";
import Env from "env";
import video0 from "assets/videos/background_0.mp4";
import video1 from "assets/videos/background_1.mp4";
import video2 from "assets/videos/background_2.mp4";
import ElementUtils from "services/utils/ElementUtils";
import imgMorthSystem from "assets/images/features/morph-system.png";
import imgArmorSets from "assets/images/features/armor-sets.png";
import imgGlyphs from "assets/images/features/glyphs.png";

const videos: string[] = [
    video0, video1, video2
];

const videoEl = document.createElement("video");
videoEl.id = "bg-video";
videoEl.playsInline = true;
videoEl.autoplay = true;
videoEl.muted = true;
videoEl.loop = true;
videoEl.poster = resolvePublicUrl(Env.BACKGROUND_IMAGE);

const videoSrc = ElementUtils.createElement("source", { type: "video/mp4" });
videoEl.appendChild(videoSrc);

const logoSrc = resolvePublicUrl(process.env.REACT_APP_IMAGE_LOGO)

const Home: React.FC<{}> = () => {
    const [videoLoaded] = useState(false);

    const [video, otherVideos] = useMemo(() => {
        const videoIndex = Random.int(videos.length);
        return [
            videos[videoIndex],
            videos.slice().splice(videoIndex, 1)
        ];
    }, []);

    const videoRootRef = useMemo(() => React.createRef<HTMLDivElement>(), []);
    useEffect(() => {
        if (!videoRootRef.current) {
            return;
        }

        const sources = videoEl.getElementsByTagName("source");
        if (sources.length === 1) {
            
        }

        videoSrc.src = video;
        videoRootRef.current.append(videoEl);
        videoEl.style.display = "block";
        videoEl.load();

        if (videoEl.parentElement === document.body) {
            document.body.removeChild(videoEl);
        }

        return () => {
            videoEl.style.display = "none";
            document.body.append(videoEl);
        };
    }, [videoRootRef.current, video]);

    return (
        <div className="space-fill height-relative index">
            {/*
                Preload other videos when main video is done
                loading so next page load will be faster
             */}
            {videoLoaded && otherVideos.map(vid => (
                <video key={vid} style={{ display: "none" }}>
                    <source src={vid}/>
                </video>
            ))}
            <section id="intro">
                <div className="overlay" />

                <div ref={videoRootRef} />

                <Row align="middle" justify="center" style={{ marginTop: 30 }}>
                    <Col>
                        <img
                            src={logoSrc}
                            alt="Enchant Logo"
                            style={{
                                maxWidth: 900,
                                width: "100%"
                            }}
                        />
                    </Col>
                </Row>
                <div className="space-fill"></div>
                <Space
                    align="center"
                    direction="vertical"
                    style={{
                        marginBottom: 80,
                        zIndex: 1
                    }}
                >
                    <img
                        className="play-free"
                        src={playFreeNowSrc}
                        alt="Play Free Now"
                    />

                    <PlayButton />
                </Space>
            </section>

            <InfoSection
                heading={<>Welcome to<span> Enchant</span></>}
                description={(
                    <>
                        Enchant is a 317 server that offers unique custom content on a whole new level.
                        Experience brand new monsters, systems, maps, and mechanics never seen before in the rsps community.
                        Infuse power into your items to grow in strength, transform into gods from legend and channel their
                        overwhelming destruction through your weapons, compete against other players in contests and leaderboards
                        and conqueror all 16 tiers to claim your seat at the top!
                    </>
                )}
                panels={[
                    {
                        key: "1",
                        title: "NEW POWER AT YOUR FINGERTIPS",
                        description: "Collect and transform into powerful creatures with our new Morph feature! Morphing allows you to invoke of the power of the gods themselves, increasing damage, attack speed, and granting you a unique special powerful effect unique to the morph. Can you collect them all and be ready for any situation?",
                        thumbUrl: imgMorthSystem
                    },
                    {
                        key: "2",
                        title: "FORGE YOUR OWN PATH",
                        description: "Create your own unique play style by collecting and creating powerful Glyphs that can be equipped into new equipment slots that enhance your combat, defense, treasure finding, and skilling capabilities. You can have up to 4 Glyphs equipped at a time and swap them in and out to best match the encounter you're dealing with. What path will you forge?",
                        thumbUrl: imgGlyphs
                    },
                    {
                        key: "3",
                        title: "BECOME THE STRONGEST",
                        description: "Rise to the top by challenging powerful enemies and claiming their boon as your own. Collect  unique weapons, armor, experience, and artifacts to greatly increase your power and assist you on your conquest to become the strongest hero on Enchant. Let none stand in your way!",
                        thumbUrl: imgArmorSets
                    },
                    // {
                    //     key: "4",
                    //     title: "EXPLORE THE ENCHANTED WORLD",
                    //     description: "Explore the new Enchant archipelago, a series of Islands each more treacherous and mysterious than the last. Collect unique items, defeat roaming bosses, find and close rifts, and collect unique treasures. What are you waiting for? Your Enchanted journey awaits!",
                    //     thumbUrl: "https://cdn.battlerite.com/www3/images/static/videos/consumables.png"
                    // }
                ]}
            />

            <section
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundImage: `url(${resolvePublicUrl(Env.BACKGROUND_IMAGE)})`,
                    position: "relative"
                }}
            >
                <div className="blured-underlay" style={{ zIndex: 1 }}/>
                <div className="bar top" />
                <Space
                    align="center"
                    direction="vertical"
                    style={{
                        marginTop: 80,
                        marginBottom: 80,
                        zIndex: 1
                    }}
                >
                    <img
                        className="play-free"
                        src={playFreeNowSrc}
                        alt="Play Free Now"
                    />
                    <PlayButton />
                </Space>
                <div className="bar bottom black" />
            </section>

        </div>
    )
}

export default Home;